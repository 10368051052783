<template>
  <div class="zone">
    <TJDetailTitle :titleData="'仪器原始信息'" />
    <TJDetailMenu :dataList="dataList" />
    <TJDetailTitle :titleData="'仪器状态'" />
    <TJDetailMenu :dataList="dataList2" />
    <TJDetailTitle :titleData="'日志信息'" />
    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="loading">
      <el-table-column
        prop="create_date"
        label="时间"
        align="center"
        width="260">
      </el-table-column>
      <el-table-column
        prop="log"
        label="日志内容"
        align="left"
      >
      </el-table-column>
    </el-table>
    <div class="el_pagination_style">
      
      <el-pagination
        :hide-on-single-page="total <= 0"
        background
        layout="total,prev, pager, next,jumper"
        :total="total"
        :current-page.sync="pageNum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <TJDetailTitle :titleData="'开关机信息'" />
    <TJDetailMenu :dataList="dataList1" />
    <TJDetailTitle :titleData="'通讯设备运行信息'" />
    <TJDetailMenu :dataList="dataList3" />


    <TJDetailTitle :titleData="'统计信息'" v-if="instrumentsStatistic.length!=0"/>
    <div class="indentone">
      <div class="indent1" @click="changePage(0)" v-if="instrumentsStatistic.includes('Dosage')">
        <div class="indent1left">用量统计：</div>
        <div class="indent1right">
          <div>{{ detailData.total_used }}</div>
          <div class="jiantou"></div>
        </div>
      </div>
      <div class="indent1" @click="changePage(1)" v-if="instrumentsStatistic.includes('Maintain')">
        <div class="indent1left">维修统计：</div>
        <div class="indent1right">
          <div>{{ detailData.total_repaired }}</div>
          <div class="jiantou"></div>
        </div>
      </div>
      <div class="indent1" @click="changePage(2)" v-if="instrumentsStatistic.includes('recordwarnings')">
        <div class="indent1left">告警统计：</div>
        <div class="indent1right">
          <div>
            {{ detailData.total_warning }}
          </div>
          <div class="jiantou"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import { recode_log } from '@/api/search.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
import { getinstrumentsStatistic } from '@/router/menuMain.js'

export default {
  name: 'InstrumentsDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      loading: false,
      hospital_id: '',
      dataList: [
        {
          name: '医院',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '仪器型号',
          value: '',
          width: '25%',
        },
        {
          name: '仪器位置',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '经销商',
          value: '',
          width: '25%',
        },
        {
          name: '售后人员',
          value: '',
          width: '25%',
        },
        {
          name: '装机时间',
          value: '',
          width: '25%',
        },
        {
          name: '试剂总用量',
          value: '',
          width: '25%',
        },
        
      ],
      dataList1: [
        {
          name: '状态',
          value: '',
          width: '50%',
        },
        {
          name: '时间',
          value: '',
          width: '50%',
        },
       
      ],
      dataList2: [
        /* 仪器部要求修改 */
        {
          name: '上位机版本',
          value: '',
          width: '100%',
        },
        {
          name: '下位机版本号',
          value: '',
          width: '100%',
        },
        {
          name: '进样架版本',
          value: '',
          width: '25%',
        },
        {
          name: 'GPRS 版本',
          value: '',
          width: '25%',
        },
        {
          name: '完整版本',
          value: '',
          width: '25%',
        },
        {
          name: '光源累计使用时间',
          value: '',
          width: '25%',
        },
        {
          name: '光源AD值',
          value: '',
          width: '25%',
        },
        {
          name: '纯水状态',
          value: '',
          width: '25%',
        },
        {
          name: '废液状态',
          value: '',
          width: '25%',
        },
        {
          name: '反应盘温度值',
          value: '',
          width: '25%',
        },
        {
          name: '管路压力值AD值',
          value: '',
          width: '100%',
          rightBorder: false,
        },
        {
          name: '比色杯状态',
          value: '',
          width: '100%',
          bottomBorder: false,
        },
        // {
        //   name: '上位机版本',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '下位机版本号',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '进样架版本',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: 'GPRS 版本',
        //   value: '',
        //   width: '25%',
        //   rightBorder: false,
        // },
        // {
        //   name: '完整版本',
        //   value: '',
        //   width: '25%',
        //   bottomBorder: false,
        // },


        // {
        //   name: '光源累计使用时间',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '光源AD值',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '纯水状态',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '废液状态',
        //   value: '',
        //   width: '25%',
        //   rightBorder: false,
        // },
        // {
        //   name: '反应盘温度值',
        //   value: '',
        //   width: '25%',
        // },
        // {
        //   name: '管路压力值AD值',
        //   value: '',
        //   width: '50%',
        //   rightBorder: false,
        // },
        // {
        //   name: '比色杯状态',
        //   value: '',
        //   width: '100%',
        //   bottomBorder: false,
        // },
        
      ],
      dataList3: [
        {
          name: 'LAC',
          value: '',
          width: '25%',
        },
        {
          name: 'CELLID',
          value: '',
          width: '25%',
        },
        {
          name: 'sim卡',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
      ],
      detailId: '',
      detailData: {},
      instrumentsStatistic: []
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitinstrumentDetail().then(res => {
      this.get_recode_log_list();
    })
  },
  mounted() {
    this.instrumentsStatistic = getinstrumentsStatistic();
  },
  methods: {
    gitinstrumentDetail() {
      return new Promise((resolve, reject) => {
        apiDetail
          .instrumentDetail({ instrument_id: this.detailId })
          .then((res) => {
            this.detailData = res.data
            this.hospital_id = this.detailData.hospital_id
            this.dataList[0].value = this.detailData.hospital_name
            this.dataList[1].value = this.detailData.instrument_id
            this.dataList[2].value = this.detailData.platform_unique
            this.dataList[3].value = this.detailData.label?this.detailData.label:this.detailData.hos_pos_name
            this.dataList[4].value = this.detailData.customer_name
            this.dataList[5].value = this.detailData.engineer
            this.dataList[6].value = this.detailData.last_install_date
            this.dataList[7].value = this.detailData.total_used

            this.dataList1[0].value = this.detailData.log?.log
            this.dataList1[1].value = this.detailData.log?.create_time_stamp
            
            /* 仪器部为了外部检测使用
              2023年12月12日 
              预计今天结束，过后即可改回
            */
            let instrumentsList = ['21N00001','21N00002','21N00003','21N00004']
            if(instrumentsList.includes(this.detailData.instrument_id)){
              this.dataList2[2].value = 'V1.0.1'
              this.dataList2[4].value = 'V1.0.1'
            }else {
              this.dataList2[2].value = this.detailData.version_sample
              this.dataList2[4].value = this.detailData.version_full
            }
            this.dataList2[0].value = this.detailData.version_upper
            this.dataList2[1].value = this.detailData.version_lower
            // this.dataList2[2].value = this.detailData.version_sample
            this.dataList2[3].value = this.detailData.version_gprs
            // this.dataList2[4].value = this.detailData.version_full
            this.dataList2[5].value = this.detailData.light_usage_total
            this.dataList2[6].value = this.detailData.light_ad_value
            this.dataList2[7].value = this.detailData.status_pure_water
            this.dataList2[8].value = this.detailData.status_waste_water
            this.dataList2[9].value = this.detailData.temp_plate?this.detailData.temp_plate.toFixed(2):''
            this.dataList2[10].value = this.detailData.pipe_ad_value
            this.dataList2[11].value = this.detailData.status_cuvette
          
            this.dataList3[0].value = this.detailData.pos_code?parseInt(this.detailData.pos_code,16):''
            this.dataList3[1].value = this.detailData.pos_id?parseInt(this.detailData.pos_id,16):''
            this.dataList3[2].value = this.detailData.sim_card

            resolve();
          })
      })
     
    },
    handleSizeChange(val) {
      this.pageNum = val
      this.get_recode_log_list()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.get_recode_log_list()
    },

    // 获取仪器日志
    get_recode_log_list() {
      this.tableData = []
      this.loading = true
      recode_log({
        page: this.pageNum,
        size: this.pageSize,
        hospital_id: this.hospital_id,
        instrument_id: this.detailId
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.data.data
            this.total = res.data.count
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 跳转页面
    changePage(value) {
      switch (value) {
        case 0: //用量统计
          this.$router.push({
            path: 'Device',
            query: { id: this.detailId },
          })
          break
        case 1: //维修统计
          this.$router.push({
            path: 'GoVolume',
            query: { id: this.detailId },
          })
          break
        case 2: //告警统计
          // localStorage.setItem('mode', 'recordwarnings')
          sessionStorage.setItem('mode', 'recordwarnings')
          localStorage.setItem('recordwarnings', this.detailId )
          this.$router.push({
            path: 'SearchPage',
          })
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.indentone {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(5);
}
.indent1 {
  height: 2.385417vw;
  background-color: transparent;
  display: flex;
  margin-bottom: 5px;
  border-bottom: 1px solid #ebf0f6;
  &:hover {
    background: #999999;
  }
}
.indent1left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}
.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.indent1right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  cursor: pointer; 
}
.jiantou {
  width: 7px;
  height: 7px;
  border-top: 4px solid #696464;
  border-right: 4px solid #696464;
  transform: rotate(45deg);
}
</style>
